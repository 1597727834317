<template>
  <v-card>
    <v-card-title>
      <v-btn
        id="btn1"
        v-can="'exam.create'"
        color="primary"
        dark
        @click="dialogModal(true, 'create')"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        {{ $('create') }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      class="dd"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>
      <template v-slot:header.actions="{ header }">
        {{ header.text }}
      </template>

      <template v-slot:item.active="{ item }">
        <v-btn v-if="item.active" color="success" @click="dialogModalQr(true, 'show', item.id)">
          جاري
        </v-btn>
        <v-chip v-else label color="#9e9e9e">
          متوقف
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div id="actions" class="text-center">
          <v-progress-circular
            v-if="loadings.indexOf(item.id) !== -1"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-menu v-else offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item label="عرض الدرجات" color="info" icon="mdi-account-group" @click="dialogModalEntries(true, 'entries', item.id)"></menu-item>
              <menu-item v-if="item.active" label="اغلاق الامتحان" color="error" icon="mdi-stop" @click="updateStatus(item, false)"></menu-item>
              <menu-item v-if="!item.active" label="فتح الامتحان" color="success" icon="mdi-check" @click="updateStatus(item, true)"></menu-item>
              <menu-item :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item.id)"></menu-item>
              <menu-item :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <action-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></action-dialog>
    <entries-dialog
      :dialog-modal="dialogModal"
      :data="dialogDataEntries"
      :translation-key="translationKey"
      @update:table="fetch"
    ></entries-dialog>

    <qr-dialog
      :dialog-modal="dialogModal"
      :data="dialogDataQr"
      :translation-key="translationKey"
      @update:table="fetch"
    ></qr-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import ActionDialog from './dialog.vue'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import MenuItem from '@/components/menu/menuItem.vue';
import QrDialog from '@/views/pages/exam/QRDialog.vue'
import EntriesDialog from '@/views/pages/exam/EntriesDialog.vue'

export default {
  name: 'Exams',
  components: { QrDialog, DatatableHeader, ActionDialog, MenuItem, EntriesDialog },
  data: () => ({
    translationKey: 'pages.exam',
    filters: false,
    items: [],
    loadings: [],
    total: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogDataQr: {
      dialog: false,
      type: 'create',
      id: null,
    },
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    dialogDataEntries: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'title',
        align: 'center',
        sortable: false,
        value: 'title',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'subject_name',
        align: 'center',
        sortable: false,
        value: 'subject.name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'stage_name',
        align: 'center',
        sortable: false,
        value: 'stage.name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'entries_count',
        align: 'center',
        sortable: false,
        value: 'entries_count',
        sort: true,
      },
      {
        text: 'active',
        align: 'center',
        sortable: false,
        value: 'active',
        sort: true,
        filter: {
          menu: false,
          type: 'switch',
        },
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  mounted() {
    this.options = getOptions(this.$route, this.options);
    this.fetch();
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        filters,
      })
      const response = await axios.get(`/exams/dt?${query}`);
      this.items = response.data.data.data
      this.total = response.data.data.total
      this.loading = false
    },
    downloadPDF() {
      window.print()
    },
    async updateStatus(item, active) {
      this.loadings.push(item.id);
      await axios.post(`/exams/edit/${item.id}`, {
        ...item,
        active,
      });
      this.loadings.splice(this.loadings.indexOf(item.id), 1);
      item.active = active;
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    dialogModalQr(dialog, type = this.dialogData.type, id = null) {
      this.dialogDataQr.dialog = dialog;
      this.dialogDataQr.type = type;
      this.dialogDataQr.id = id;
    },
    dialogModalEntries(dialog, type = this.dialogData.type, id = null) {
      this.dialogDataEntries.dialog = dialog;
      this.dialogDataEntries.type = type;
      this.dialogDataEntries.id = id;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
