<template>
  <v-dialog v-model="data.dialog" persistent :fullscreen="isCreate || isUpdate">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading" class="d-flex flex-column">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate, 'form-primary': isCreate}"
      >
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text v-if="isDelete && item">
        <h2 class="my-3">
          {{ $('delete_msg', [item.name]) }}
        </h2>
      </v-card-text>
      <v-card-text v-else>
        <v-tabs
          v-model="tab"
          align-with-title
          grow
        >
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab>
            {{ $('info') }}
          </v-tab>
          <v-tab>
            {{ $('questions') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-text-field
                      v-model="form.title"
                      v-max-length="200"
                      :label="$('title')"
                      :rules="rules.title"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-select
                      v-model="form.stage_id"
                      :items="stages"
                      :rules="rules.stage_id"
                      clearable
                      item-text="name"
                      item-value="id"
                      :label="$('stage')"
                      required
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-select
                      v-model="form.subject_id"
                      :items="subjects_filtered"
                      :rules="rules.subject_id"
                      :disabled="!form.stage_id"
                      clearable
                      item-text="name"
                      item-value="id"
                      :label="$('subject')"
                      required
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="form.question_degree"
                      v-max-length="200"
                      :label="$('question_degree')"
                      type="number"
                      :rules="rules.question_degree"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="form.question_count"
                      v-max-length="200"
                      :label="$('question_count')"
                      type="number"
                      :rules="rules.question_count"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-textarea
                      v-model="form.notes"
                      v-max-length="200"
                      rows="2"
                      :label="$('notes')"
                      required
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-switch
                      v-model="form.active"
                      v-max-length="200"
                      :label="$('active')"
                      :rules="rules.active"
                      required
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-right">
                      {{ $('question') }}
                    </th>
                    <th class="text-right">
                      {{ $('type') }}
                    </th>
                    <th class="text-right">
                      {{ $('actions') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in questions"
                    :key="is_question_form ? `${index}_t` : `${index}_b`"
                  >
                    <td>{{ item.question }}</td>
                    <td>{{ $(`question_types.${item.type}`) }}</td>
                    <td>
                      <v-btn small color="info" class="me-2" @click="openQuestionDialog(item, index)">
                        {{ $('edit_question') }}
                      </v-btn>
                      <v-btn color="error" small @click="questions.splice(index, 1)">
                        {{ $('delete_question') }}
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <v-btn block small color="info" @click="openQuestionDialog()">
                        {{ $('add_question') }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-alert v-if="questions.length < form.question_count" type="error" border="left" class="text-white">
          {{ $('question_count_alert') }}
        </v-alert>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading || questions.length < form.question_count"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="is_question_form" persistent max-width="600">
      <v-card>
        <v-card-title class="form-info">
          <span v-if="question_form.id">{{ $('question_edit_form') }}</span>
          <span v-else>{{ $('question_add_form') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid_question"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="question_form.question"
                    v-max-length="200"
                    :label="$('question')"
                    :rules="rules.question"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="question_form.type"
                    :items="['mcq', 'mcq_multiple', 'input']"
                    :rules="rules.type"
                    :label="$('type')"
                    required
                  >
                    <template v-slot:selection="{ attrs, item}">
                      <span>
                        {{ $(`question_types.${item}`) }}
                      </span>
                    </template>
                    <template v-slot:item="{ attrs, item}">
                      <span>
                        {{ $(`question_types.${item}`) }}
                      </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  v-if="question_form.type === 'mcq' || question_form.type === 'mcq_multiple'"
                  cols="12"
                  sm="12"
                >
                  <v-combobox
                    ref="options"
                    v-model="question_form.options"
                    v-max-length="200"
                    :label="$('options')"
                    required
                    multiple
                    outlined
                    small-chips
                    deletable-chips
                  >
                  </v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-if="question_form.type !== 'input'"
                    v-model="question_form.correct_answer"
                    :items="question_form.options"
                    :disabled="!question_form.options.length"
                    :label="$('correct_answer')"
                    required
                    multiple
                    outlined
                    small-chips
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip v-bind="attrs" :input-value="selected">
                        <span class="pe-2">
                          {{ item }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <v-combobox
                    v-if="question_form.type === 'input'"
                    v-model="question_form.correct_answer"
                    :items="question_form.options"
                    :label="$('correct_answer')"
                    required
                    multiple
                    outlined
                    small-chips
                    deletable-chips
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light"
            outlined
            @click="is_question_form = false"
          >
            {{ $t('form.cancel') }}
          </v-btn>
          <v-btn
            color="info"
            :disabled="!valid_question || !question_form.correct_answer.length"
            @click="submit_question"
          >
            {{ $t('form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'ExamDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    tab: 0,
    loading: true,
    submitLoading: false,
    valid: false,
    valid_question: false,
    item: null,
    stages: [],
    subjects: [],
    questions: [],
    is_question_form: false,
    question_form_index: null,
    question_form: {
      id: null,
      question: '',
      options: [],
      correct_answer: [],
      type: null,
    },
    form: {
      title: '',
      notes: '',
      stage_id: '',
      subject_id: '',
      question_degree: '',
      question_count: '',
      active: true,
    },
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    subjects_filtered() {
      return this.subjects.filter(subject => subject.stage_id === this.form.stage_id)
    },
    rules() {
      const rules = {}
      rules.title = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.title`)]),
      ]
      rules.question = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.question`)]),
      ]
      rules.type = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.type`)]),
      ]
      rules.notes = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.notes`)]),
      ]
      rules.stage_id = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.stage_id`)]),
      ]
      rules.subject_id = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.subject_id`)]),
      ]
      rules.question_degree = [
        v => (v !== undefined && v > 0) || this.$t('form.required', [this.$t(`${this.translationKey}.modal.question_degree`)]),
      ]
      rules.question_count = [
        v => (v !== undefined && v > 0) || this.$t('form.required', [this.$t(`${this.translationKey}.modal.question_count`)]),
      ]
      rules.active = [
        v => v !== undefined || this.$t('form.required', [this.$t(`${this.translationKey}.modal.active`)]),
      ]

      return rules
    },
  },
  watch: {
    'question_form.type': {
      handler(val) {
        this.question_form.options = []
        this.question_form.correct_answer = []
      },
    },
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true
          this.tab = 0
          if (val.id) {
            const stage = await axios.get(`/exams/${val.id}`)
            this.item = stage.data.data
            this.form.title = this.item.title
            this.form.notes = this.item.notes
            this.form.stage_id = this.item.stage_id
            this.form.subject_id = this.item.subject_id
            this.form.question_degree = this.item.question_degree
            this.form.question_count = this.item.question_count
            this.form.active = this.item.active
            this.questions = this.item.questions
          }
          if (!val.dialog) {
            this.resetForm()
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false
        }
      },
    },
  },
  async mounted() {
    const response = await axios.get('/stages')
    this.stages = response.data.data
    const subjectsResponse = await axios.get('/subjects')
    this.subjects = subjectsResponse.data.data
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true
        if (this.isDelete) {
          await axios.delete(`/exams/delete/${this.data.id}`)
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return
        }
        if (this.$refs.form.validate() === false) {
          return
        }
        if (this.isCreate) {
          await axios.post('/exams/create', {
            title: this.form.title,
            notes: this.form.notes,
            stage_id: this.form.stage_id,
            subject_id: this.form.subject_id,
            question_degree: this.form.question_degree,
            question_count: this.form.question_count,
            active: this.form.active,
            questions: this.questions,
          })
          await toast.dispatch('success', this.$t('form.success.create'))
        } else if (this.isUpdate) {
          await axios.post(`/exams/edit/${this.data.id}`, {
            title: this.form.title,
            notes: this.form.notes,
            stage_id: this.form.stage_id,
            subject_id: this.form.subject_id,
            question_degree: this.form.question_degree,
            question_count: this.form.question_count,
            active: this.form.active,
            questions: this.questions,
          })
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.form = {
        title: '',
        notes: '',
        stage_id: '',
        subject_id: '',
        question_degree: '',
        question_count: '',
        active: true,
      }
      this.item = null
    },
    openQuestionDialog(question = undefined, index = undefined) {
      if (!question) {
        this.question_form_index = null
        this.question_form = {
          id: null,
          question: '',
          options: [],
          correct_answer: [],
          type: null,
        }
      } else {
        this.question_form_index = index
        this.question_form = {
          ...question,
        }
      }
      this.is_question_form = true
    },
    submit_question() {
      if (this.question_form_index !== null && this.question_form_index !== undefined) {
        this.questions[this.question_form_index] = this.question_form
      } else {
        this.questions.push(this.question_form)
      }
      this.is_question_form = false
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters)
    },
  },
}
</script>
